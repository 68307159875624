import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environmentLoader } from 'src/environments/environmentLoader';

import { ApiService } from './services/hub-api/api.service';
import { RoutingStateService } from './services/routing-state.service';
import {Subscription} from 'rxjs';
declare const tarteaucitron: { state: { piwikpro: boolean } };
// eslint-disable-next-line no-underscore-dangle
declare const _paq: Array<Array<string>>;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'hub-entreprise-front-agent';
  isHomePage = false;
  private readonly routerSubscription: Subscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private api: ApiService,
    private routingStateService: RoutingStateService
  ) {
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
    this.routingStateService.loadRouting();

    // Souscription aux événements du routeur
    this.routerSubscription = this.router.events.subscribe(
    // @ts-ignore
      (event: Event) => {
        // Gestion de l'événement de navigation
        this.handleNavigationEvent(event);
      }
    );
  }

  async loadEnvironment() {
    await environmentLoader.then(env => {
      this.api.hubApiHost = env.settings.hubApi.host;
    });
  }

  async ngOnInit() {
    this.loadEnvironment();
    this.router.events.subscribe(
      () => (this.isHomePage = this.router.url === '/')
    );
  }

  /**
   * Méthode appelée lorsque le composant est détruit.
   * Elle se charge de se désabonner des événements du routeur afin d'éviter les fuites de mémoire.
   */
  public ngOnDestroy(): void {
    // Désabonnement des événements du routeur pour prévenir les fuites de mémoire
    this.routerSubscription.unsubscribe();
  }

  /**
   * Gère les événements de navigation du routeur.
   * Si l'événement est une fin de navigation (NavigationEnd) et que le consentement Piwik Pro a été donné,
   * il déclenche le suivi de la page vue.
   * @param event - L'événement du routeur à traiter.
   */
  private handleNavigationEvent(event: Event): void {
    // Vérifie si l'événement est une fin de navigation et si le consentement a été donné
    if (event instanceof NavigationEnd && this.isPiwikProConsentGiven()) {
      // Suivi de la page vue avec l'URL redirigée
      this.trackPageView(event.urlAfterRedirects);
    }
  }

  /**
   * Vérifie si le consentement pour le suivi Piwik Pro a été donné.
   * Cela passe par l'état de tarteaucitron qui gère les préférences des utilisateurs pour les cookies.
   * @returns true si le consentement Piwik Pro a été donné, sinon false.
   */
  private isPiwikProConsentGiven(): boolean {
    // Vérifie l'état du consentement pour Piwik Pro
    return tarteaucitron.state.piwikpro;
  }

  /**
   * Effectue le suivi de la page vue avec Piwik Pro.
   * Si l'objet global _paq est défini, cela envoie un événement de suivi de la page vue à Piwik Pro.
   * @param url - L'URL de la page à suivre.
   */
  private trackPageView(url: string): void {
    // Vérifie si _paq (l'objet de suivi Piwik Pro) est défini
    if (typeof _paq !== 'undefined') {
      // Pousse l'URL personnalisée à Piwik Pro pour le suivi de la page
      _paq.push(['setCustomUrl', url]);
      // Envoie un événement de suivi de la page vue
      _paq.push(['trackPageView']);
    } else {
      // Affiche un message d'avertissement si _paq n'est pas défini
      console.warn(
        'Le suivi Piwik Pro (_paq) n’est pas défini. Assurez-vous que le script Piwik Pro est correctement chargé et configuré.'
      );
    }
  }
}
